if ($.browser.msie) {
	$('html').addClass('ie').addClass('ie'+parseInt($.browser.version,10));
}

var emploisuccess = function() {
	$('.js-emploiForm').fadeOut(500,function() {$('.emploisuccess').fadeIn();});
};

var emploierror = function() {
	$('.js-emploiForm .btn').fadeIn();
	$('.js-emploiForm').append('<p class="text-error">Une erreur est survenu, veuillez r&eacute;essayer plus tard.</p>');
};



var retina = window.devicePixelRatio > 1;
$(function() {

	var device = Detect();
    if (device=='smartphone') {
        $('body').addClass('mobile');
    }

    if (device=='desktop') {
        $('body').addClass('computer');
    }
	if ($('.js-contactForm').length>0) {
		$('.js-contactForm').validate({
			errorPlacement: function(error,element) {
				element.closest('label').addClass('error-label');
				error.insertAfter(element);
				},
			success: function(label,element) {
				$(element).closest('label').removeClass("error-label");
				}
			});
		}
	$('.js-contactForm').submit(function() {
		var obj = $(this);
		if (obj.valid()) {
			$.post('/json?module=contactform&method=submit',$(this).serializeArray(),function(data) {
				if(data.status!="Erreur")
					obj.hide().next().show();
				else{
					$('.js-error-form').remove();
					$('.js-contactForm').append('<div class="js-error-form alert alert-danger" role="alert">'+e.message+'</div>');
				}
			});
		}
		return false;
	});

	$("body").on('click','li.js-noclick a',function(){
		$(this).parent().toggleClass('in');
		return false;
	});

	$(".scrollto").click(function() { // Mettre class .scrollto sur le bouton et mettre attribut data-target="#id" et data-duration="1000"
		var scrolltarget = $(this).data('target');
	    $('html, body').animate({
	        scrollTop:$(scrolltarget).offset().top
	    }, $(this).data('duration'));
	    return false;
	});

	//svgeezy.init();

	$('body').on('touchstart.dropdown', '.dropdown-menu', function (e) { e.stopPropagation(); }); // iPad fix for unclickable nav/subnav

	$('.carousel').carousel({
	  interval: 5000
	});
	
	$("button.close").on('click',function(){
		$(this).parent().slideUp();
	});

	if ($.browser.msie && $.browser.version<9) {
		$('header nav ul > li:last-child').addClass('last').prev().addClass('last');
	}

	if ($('.fancybox').length>0) {
		$('.fancybox').magnificPopup({ 
		  type: 'image',
		  gallery:{enabled:true},
		  callbacks: {
    
		    buildControls: function() {
		      // re-appends controls inside the main container
		      this.contentContainer.append(this.arrowLeft.add(this.arrowRight));
		    }
		    
		  }
		});
	}

	if ($('.fancyboxvideo').length>0) {
		$('.fancyboxvideo').magnificPopup({ 
		  type: 'iframe',
		  gallery:{enabled:true}

		});
	}

	$(window).load(function() {
        $("body").removeClass("preload");
    });

	$('.js-emploiForm .btn').click(function() {
		$('.js-emploiForm').attr('action', '/json?module=emploiform&method=submit');
	});

	$('.js-emploiForm').submit(function() {
		$(this).find('.btn').fadeOut();
	});

	/* Mobile Nav Events START */
	var el = $('.mobile-nav > ul'),
		curHeight = el.height(),
		autoHeight = 0,
		subHeight = 0;
		menuHeight = 40;

	$('.bt-menu, .btn-menu').click(function(){
		    autoHeight = el.css('height', 'auto').height();
		if(!el.hasClass('open')){
			el.height(curHeight).animate({height: autoHeight}, 400);
		}else{
			el.height(autoHeight).animate({height: curHeight}, 400);
		}
		//console.log('click!');
		el.toggleClass('open');
		return false;
	});

	$('.mobile-nav > ul > li > a').click(function(){
		if($(this).parent().children().length > 1){
			$(this).next().addClass('active');
			subHeight = $(this).next().find('ul').height()+menuHeight;
			if(subHeight>autoHeight){
				el.height(autoHeight).animate({height: subHeight}, 200);
			}
			return false;
		}
	});

	$('.mobile-nav > ul > li > div b').click(function(){
		$('.mobile-nav div').removeClass('active');
		if(subHeight>autoHeight){
			el.height(subHeight).animate({height: autoHeight}, 200);
		}
		return false;
	});
	/* Mobile Nav Events END */

	$(window).resize(function() {
		$('.wrapper-facebook .facebook .fb-inner').css('height',$(window).height());
	});
	$('.wrapper-facebook .facebook .fb-inner').css('height',$(window).height());

	$(window).scroll(function(event) {
		var h = parseInt($('header').css('height'));
		if ($(window).scrollTop()>=h) $('.computer .nav-fixed').addClass('active');
		else $('.computer .nav-fixed').removeClass('active');
	});

	$(window).resize(function() {
		$('.home.computer header .wrapper-slider, .home.computer header .wrapper-slider .item').css('height',$(window).height()-40);
	});
	$('.home.computer header .wrapper-slider, .home.computer header .wrapper-slider .item').css('height',$(window).height()-40);

	$(".select-wrapper select").change(function(){
        $(this).parent().find('span').text($(this).parent().find("select option:selected").text());
    });

	$('.custom-accordion').click(function() {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active').next().slideUp();
        }else{
            //$('.custom-accordion.active').removeClass('active').next().slideUp();
            $(this).addClass('active').next().slideDown();
        }
        return false;
    });



    $(window).on('load', function() {
    	// Check all tables. You may need to be more restrictive.
	    $('table.responsive-table').each(function() {
	        var element = $(this);
	        // Create the wrapper element
	        var scrollWrapper = $('<div />', {
	            'class': 'scrollable',
	            'html': '<div />' // The inner div is needed for styling
	        }).insertBefore(element);
	        // Store a reference to the wrapper element
	        element.data('scrollWrapper', scrollWrapper);
	        // Move the scrollable element inside the wrapper element
	        element.appendTo(scrollWrapper.find('div'));
	        // Check if the element is wider than its parent and thus needs to be scrollable
	        if (element.outerWidth() > element.parent().outerWidth()) {
	            element.data('scrollWrapper').addClass('has-scroll');
	        }
	        // When the viewport size is changed, check again if the element needs to be scrollable
	        $(window).on('resize orientationchange', function() {
	            if (element.outerWidth() > element.parent().outerWidth()) {
	                element.data('scrollWrapper').addClass('has-scroll');
	            } else {
	                element.data('scrollWrapper').removeClass('has-scroll');
	            }
	        });
	    });
	});

function objectifyForm(formArray) {//serialize data function

  var returnArray = {};
  for (var i = 0; i < formArray.length; i++){
    returnArray[formArray[i]['name']] = formArray[i]['value'];
  }
  return returnArray;
}

if ($('.Soumission_form').length>0)
{
	//Select Change
	$('form select,form [name="dimension"]').change(function(e) {
		var name = $(this).attr('name');
		var value = $(this).val();
		var option = $(this).find('option:selected');
		var form = $(this).parents('form');

		form.find("[data-"+name+"]").hide().attr('disabled','disabled');
		form.find("[data-"+name+"='"+value+"']").show().removeAttr('disabled');
	});

	var products = [];
	$('.Soumission_form select[name="type"]').change(function(e) {
		var property = $(this).val();
		var soumission_form = $(this).parents('form');
		soumission_form.find('.CategoryFilter > ul').hide();
		soumission_form.find('.CategoryFilter > ul[data-property="'+property+'"]').show();
	});

	$('body').on('click','.Btn-remove', function(e) {
		e.preventDefault();
		var tr = $(this).parents('tr');
		var tbody = tr.parent();
		var index = tr.data('index');

		var product_type_label = products[i].type;

		if (!product_type_label)
		{
			product_type_label = $(this).data('categorielabel');
		}

		products.splice(index, 1);
		tbody.empty();
		for (var i = 0; i < products.length; i++) 
		{
			tbody.append('<tr data-index="'+i+'"><td>'+products[i].qty+'</td><td>'+products[i].category+'</td><td>'+products[i].width+'" x '+products[i].height+'"</td><td>'+product_type_label+'</td><td><button type="button" class="Btn-remove"></button></td></tr>');
		}

	});

	$('.Soumission_form').validate({
		errorPlacement: function(error,element) {
			element.closest('label').addClass('error-label');
			element.parent().parent().append(error);
			},
		success: function(label,element) {
			$(element).closest('label').removeClass("error-label");
			}
		});

	$('.Soumission_form button[data-submit="submit"]').click(function(e) {
		e.preventDefault();
		e.stopPropagation();
		var obj = $(this).parents('form');
		if (obj.valid()) {
			var product = objectifyForm(obj.serializeArray());
			var product_category_label = obj.data('categorielabel');
			var product_type_label = obj.find('select[name="type"] option:selected').text();

			if (!product_type_label)
			{
				product_type_label = obj.data('categorielabel');
			}

			products.push(product);
			if (product.width && product.height && product_type_label)
			{
				$('.FormItems .table tbody').append('<tr data-index="'+(products.length-1)+'"><td>'+product.qty+'</td><td>'+product_category_label+'</td><td>'+product.width+'" x '+product.height+'"</td><td>'+product_type_label+'</td><td><button type="button" class="Btn-remove"></button></td></tr>');
			}
			else
			{
				$('.FormItems .table tbody').append('<tr data-index="'+(products.length-1)+'"><td>'+product.qty+'</td><td>'+product_category_label+'</td><td class="TextAlign-center" colspan="2">'+product.comments+'</td><td><button type="button" class="Btn-remove"></button></td></tr>');
			}
			obj.find('.CategoryFilter ul').hide();
			obj[0].reset();
			obj.validate().resetForm();
			// $.post('/json?module=cartform&method=submit',$(this).serializeArray(),function(data) {
			// 	if(data.status!="Erreur"){
			// 		obj.find('.FormDiv').hide().next().show();
			// 		setTimeout(function(){ location.reload(); }, 1500);
			// 	}
			// 	else{
			// 		$('.js-error-form').remove();
			// 		$('.Soumission_form').append('<div class="js-error-form alert alert-danger" role="alert">'+data.message+'</div>');
			// 	}
			// });
		}
		return false;
	});
}

if ($('.mainSoumission_form').length>0) {
		$('.mainSoumission_form').validate({
			errorPlacement: function(error,element) {
				element.closest('label').addClass('error-label');
				element.parent().parent().append(error);
				},
			success: function(label,element) {
				$(element).closest('label').removeClass("error-label");
				}
			});
		}
	$('.mainSoumission_form').submit(function(e) {

		var obj = $(this);
		if (obj.valid()) {

			// $(this).find('button').hide().after("<img src='/assets/img/loading.gif'>");

			var formadata = new FormData(this);
			// for (var i = 0; i < file_cv.length; i++)
			// {
   //          	formadata.append("files[]", file_cv[i]);
			// }

            formadata.append("soumission", JSON.stringify(products));

            $.ajax({
                url: '/json?module=soumissionform&method=submit',
                type: 'POST',
                processData: false,
                contentType: false,
                data: formadata,
                success: function(data){
                        if(data.status!="Erreur")
                        {
                            // obj.hide().next().show();
                            // $('.FormBuilder').hide();
                            $('.FormSoumission').hide().next().show();
                            $('html, body').animate({
						        scrollTop:$('body').offset().top
						    }, 300);
                        }
                        else
                        {
                            $('.mainSoumission_form').append('<div class="js-error-form alert alert-danger" role="alert">'+data.message+'</div>');
                        }
                  }
            });
        }

        e.preventDefault();

		// var obj = $(this);
		// if (obj.valid()) {
		// 	$.post('/json?module=soumissionform&method=submit',$(this).serializeArray(),function(data) {
		// 		if(data.status!="Erreur"){
		// 			obj.find('.FormDiv').hide().next().show();
		// 			setTimeout(function(){ location.reload(); }, 1500);
		// 		}
		// 		else{
		// 			$('.js-error-form').remove();
		// 			$('.mainSoumission_form').append('<div class="js-error-form alert alert-danger" role="alert">'+data.message+'</div>');
		// 		}
		// 	});
		// }
		return false;
	});

if ($('.FormCart').length>0) {
		$('.FormCart').validate({
			errorPlacement: function(error,element) {
				element.closest('label').addClass('error-label');
				error.insertAfter(element);
				},
			success: function(label,element) {
				$(element).closest('label').removeClass("error-label");
				}
			});
		}
	$('.FormCart').submit(function() {
		var obj = $(this);
		if (obj.valid()) {
			$.post('/json?module=cartform&method=submit',$(this).serializeArray(),function(data) {
				if(data.status!="Erreur"){
					theTarget = obj.find('.FormDiv').hide().next().show();
					setTimeout(function(){ location.reload(); }, 1500);
				}
				else{
					$('.js-error-form').remove();
					$('.FormCart').append('<div class="js-error-form alert alert-danger" role="alert">'+data.message+'</div>');
				}
			});
		}
		return false;
	});

$('.Btn-send').click(function() {
	theForm = $('.FormDiv.Spaced_larger-noBottom');
	theForm.slideDown('200');
	$(this).attr('disabled', 'disabled');
});

$('.Item_actions .Btn-cart').click(function(e) {
	var _item_no = $(this).parents('.Item').data('item_no');

	$.post('/json?module=product&method=addToCart', {item_no: _item_no,qty: 1}, function(data) {
		if (data.status!="Erreur")
		{
			cartCountChange(data.message.qty);
		}
	});
});

$('.update-quantity-cart').click(function(e) {
	e.preventDefault();

	var _item_no = $(this).parents('.Item').data('item_no');

	$.post('/json?module=product&method=changeQtyCart', $('.FormCart').serializeArray(), function(data) {
		if (data.status!="Erreur")
		{
			// cartCountChange(data.message.qty);
			location.reload();
		}
	});
});

$('.remove-all-cart').click(function(e) {
	e.preventDefault();

	var _item_no = $(this).parents('.Item').data('item_no');

	$.post('/json?module=product&method=clearCart', function(data) {
		if (data.status!="Erreur")
		{
			// cartCountChange(data.message.qty);
			location.reload();
		}
	});
});

$('.cartActions').submit(function(e) {
	e.preventDefault();

	AddedConfirm = $('.AddedConfirm');
	closeAddedConfim = $('.Btn-close-confirm')
	var form = this;

	$.post('/json?module=product&method=addToCart', $(this).serializeArray(), function(data) {
		if (data.status != "Erreur")
		{
			cartCountChange(data.message.qty);
			form.reset();
			AddedConfirm.addClass('is-visible');
			setTimeout(function(){ AddedConfirm.removeClass('is-visible') }, 4000);
		}
	});

	$(closeAddedConfim).click(function(e) {
		if (AddedConfirm.hasClass('is-visible')) {
			AddedConfirm.removeClass('is-visible');
		}
	});
});

$('.remove-item-cart').click(function(e) {
	e.preventDefault();
	var _item_no = $(this).parents('tr').data('item_no');
	$('#form_remove_item input[name="item_no"]').val(_item_no);
	$('#modal_remove_item').modal();
});

$('#form_remove_item').submit(function(e) {
	$.post('/json?module=product&method=removeToCart', $(this).serializeArray(), function(data) {
		if (data.status != "Erreur")
		{
			cartCountChange(data.message.qty);
		}
	});
});

function cartCountChange(new_qty)
{
	if (new_qty > 0)
	{
		if ($('.cart-item .cart-count').length == 0)
		{
			$('.cart-item').append('<span class="cart-count">'+new_qty+'</span>');
		}
		else
		{
			$('.cart-item .cart-count').text(new_qty);
		}
	}
	else
	{
		$('.cart-item .cart-count').remove();
	}
}
});



/**
 * Module for displaying "Waiting for..." dialog using Bootstrap
 *
 * @author Eugene Maslovich <ehpc@em42.ru>
 * http://bootsnipp.com/snippets/featured/quotwaiting-forquot-modal-dialog
 * https://github.com/ehpc/bootstrap-waitingfor
 */
var waitingDialog = (function ($) {

    // Creating modal dialog's DOM
	var $dialog = $(
		'<div class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" style="padding-top:15%; overflow-y:visible;">' +
		'<div class="modal-dialog modal-m">' +
		'<div class="modal-content">' +
			'<div class="modal-header"><h3 style="margin:0;"></h3></div>' +
			'<div class="modal-body">' +
				'<div class="progress progress-striped active" style="margin-bottom:0;"><div class="progress-bar" style="width: 100%"></div></div>' +
			'</div>' +
		'</div></div></div>');

	return {
		/**
		 * Opens our dialog
		 * @param message Custom message
		 * @param options Custom options:
		 * 				  options.dialogSize - bootstrap postfix for dialog size, e.g. "sm", "m";
		 * 				  options.progressType - bootstrap postfix for progress bar type, e.g. "success", "warning".
		 */
		show: function (message, options) {
			// Assigning defaults
			var settings = $.extend({
				dialogSize: 'm',
				progressType: ''
			}, options);
			if (typeof message === 'undefined') {
				message = 'Loading';
			}
			if (typeof options === 'undefined') {
				options = {};
			}
			// Configuring dialog
			$dialog.find('.modal-dialog').attr('class', 'modal-dialog').addClass('modal-' + settings.dialogSize);
			$dialog.find('.progress-bar').attr('class', 'progress-bar');
			if (settings.progressType) {
				$dialog.find('.progress-bar').addClass('progress-bar-' + settings.progressType);
			}
			$dialog.find('h3').text(message);
			// Opening dialog
			$dialog.modal();
		},
		/**
		 * Closes dialog
		 */
		hide: function () {
			$dialog.modal('hide');
		}
	};

})(jQuery);